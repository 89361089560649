import React from 'react';
import './../App.css';
import myImage from './../logo192.png';

function MainPage() {
    return (
        <div className="App">
            <header className="App-header">
                <img
                    className="icon" src={myImage} alt="Icono de Coleccionaballs" />
                Coleccionaballs: Estamos trabajando para traer la mejor experiencia de coleccionista!
            </header>
            <a className="play-store-button" href="https://play.google.com/store/apps/details?id=com.blderdog.coleccionaballs">Descarga nuestra app en Play Store!</a>
            <br/>
            <a className="mini-manual-button" href="/manual">Manuales</a>
        </div>
    );
}

export default MainPage;