import './App.css';
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import PrivacyPage from './pages/PrivacyPage';
import DeletePage from "./pages/DeletePage";
import MainPage from "./pages/MainPage";
import RedirectPage from "./pages/RedirectPage";
import ManualPage from "./pages/ManualPage";

function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainPage/>} />
            <Route path="/privacyPolicy" element={<PrivacyPage/>} />
            <Route path="/deleteAccount" element={<DeletePage/>} />
            <Route path="/product" element={<RedirectPage/>} />
            <Route path="/manual" element={<ManualPage/>} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
