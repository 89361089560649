import React from 'react';
import './../App.css';
import myImage from "../logo192.png";

function ManualPage() {
    return (
        <div className="App">
            <header className="App-header">
                <img
                    className="icon" src={myImage} alt="Icono de Coleccionaballs" />
                Manuales
            </header>
            <a className="mini-main-manual-button" href="/manual-v1_0.pdf" target="_blank" rel="noopener noreferrer">Última versión</a>
            <br/>
            <br/>
            <h3 className="manual-history">Historial de versiones</h3>
            <a className="mini-manual-button" href="/manual-v1_0.pdf" target="_blank" rel="noopener noreferrer">v1.0</a>
        </div>
    );
}

export default ManualPage;