import React from 'react';
import './../App.css';

function DeletePage() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Eliminación de cuentas</h1>
                <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    Para solicitar la eliminación total e irreversible de tu cuenta y los datos personales asociados a ella, envía un correo a <b><a href = "mailto: admin@blderdog.com">admin@blderdog.com</a></b> desde tu correo registrado en la cuenta a eliminar siguiendo el patrón a continuación:
                </p>
                <h3>
                    Asunto
                </h3>
                <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    Eliminación de cuenta
                </p>
                <h3>
                    Cuerpo del correo
                </h3>
                <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    Yo, [nombre completo], deseo eliminar la cuenta de BlderDog para Coleccionaballs de [nombre de usuario] y toda la información personal asociada a ella de manera permanente e irreversible.
                </p>
                <h3>
                    Adjuntos
                </h3>
                <ul style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    <li>
                        Imagen de documento de identidad que muestre claramente el nombre completo indicado en el cuerpo del correo.
                    </li>
                    <li>
                        Captura de pantalla de la sección de perfil de la aplicación que muestre claramente el nombre de usuario indicado.
                    </li>
                </ul>

                <h3>
                    Procedimiento
                </h3>
                <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    Nuestro equipo enviará un correo de confirmación en un plazo de 5 días hábiles esperando respuesta afirmativa por un periodo igual. En caso de no recibir confirmación, se anula el procedimiento. En caso de recibir aprobación, se procederá en un plazo no mayor a 30 días corridos a eliminar la cuenta y la información personal asociada a ella. Una vez que el proceso esté completado, se notificará por la cadena de correo original.
                </p>

                <h2>Fecha de última actualización: Septiembre 2023.</h2>
            </header>
        </div>
    );
}

export default DeletePage;