import React from 'react';
import './../App.css';

function PrivacyPage() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Política de Privacidad Coleccionaballs</h1>
                <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    En conformidad con lo dispuesto en el artículo 19 Nº 4 de la Constitución Política de la República, la Ley Nº 19.628, y la normativa aplicable, BlderDog SpA dispone de la siguiente política de privacidad, a fin de resguardar la seguridad, confidencialidad y privacidad de sus usuarios.
                </p>
                <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    Estas políticas tienen por finalidad asegurar la correcta utilización de la información recopilada a través de las visitas de las plataformas digitales y de sus contenidos.
                </p>
                <h2>Interpretación y definiciones</h2>
                <h3>Interpretación</h3>
                <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural.
                </p>
                <h3>Definiciones</h3>
                <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    A los efectos de esta Política de privacidad:
                </p>
                <ul style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    <li>
                        <b>Cuenta:</b> Refiere a la cuenta asociada a Coleccionaballs generada para ser utilizada en dicho servicio.
                    </li>
                    <li>
                        <b>Usuario:</b> Refiere a la persona dueña de la cuenta utilizada en Coleccionaballs.
                    </li>
                    <li>
                        <b>Aplicación o app:</b> Refiere al programa de software proporcionado por BlderDog para acceder a Coleccionaballs.
                    </li>
                    <li>
                        <b>Compañía (denominada “la Compañía”, “Nosotros”, “Nos” o “Nuestro” en este Acuerdo):</b> se refiere a BlderDog SpA, Santa Elena 1960, casa 50, Colina, Región Metropolitana.
                    </li>
                    <li>
                        <b>País:</b> Refiere a Chile.
                    </li>
                    <li>
                        <b>Dispositivo:</b> Cualquier dispositivo que pueda acceder a la app, tal como un teléfono celular o una tableta digital.
                    </li>
                    <li>
                        <b>Los datos personales:</b> Cualquier información que se relacione con un individuo identificado o identificable.
                    </li>
                    <li>
                        <b>El servicio:</b> Refiere a la Aplicación.
                    </li>
                    <li>
                        <b>Proveedor de servicios:</b> Cualquier persona física o jurídica que procesa los datos en nombre de la Compañía. Se refiere a empresas de terceros o personas empleadas por la Compañía para facilitar el Servicio, para proporcionar el Servicio en nombre de la Compañía, para realizar servicios relacionados con el Servicio o para ayudar a la Compañía a analizar cómo se utiliza el Servicio.
                    </li>
                    <li>
                        <b>El Servicio de redes sociales de terceros:</b> refiere a cualquier sitio web o cualquier sitio web de red social a través del cual un Usuario puede iniciar sesión o crear una cuenta para utilizar el Servicio.
                    </li>
                    <li>
                        <b>Los Datos de uso:</b> Refieren a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, el uso de cada sección de la app).
                    </li>
                    <li>
                        <b>Usted:</b> refiere a la persona que accede o usa el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o usa el Servicio, según corresponda.
                    </li>
                </ul>


                <ol style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    <h3><li>¿Qué datos recopilamos?</li></h3>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        Al acceder y usar la app de Coleccionaballs solicitaremos que los usuarios entreguen cierta información, la cual se indica en los Términos y Condiciones.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        De este modo, BlderDog SpA recopila datos de los usuarios que hagan uso de las plataformas digitales, a través de cuatro mecanismos:
                    </p>
                    <ol style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        <h4><li>Mecanismos Automáticos:</li></h4>
                        <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                            Son aquellos procesos informáticos realizados para generar registros de las actividades de los usuarios de las plataformas digitales y cuyo objeto es establecer patrones de actividad, navegación y audiencia, que no implican la identificación personal de aquellos suscriptores y usuarios que accedan las referidas plataformas. BlderDog SpA se reserva el derecho de recopilar y usar dicha información general, a fin de establecer criterios que mejoren los contenidos de sus plataformas, en todo caso siempre disociados de la persona que dejó los datos en su navegación.
                        </p>
                        <h4><li>Mecanismos Manuales:</li></h4>
                        <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                            Son requerimientos formales y expresos de información a los usuarios que implican la recolección de datos personales tales como nombre, apellidos, correo electrónico, comuna, ciudad, región, etc. BlderDog SpA se reserva el derecho de usar y recopilar estos datos con el objeto de mejorar la calidad de información de la app y segmentar de mejor manera los temas de interés de sus usuarios en general.
                        </p>
                        <h4><li>Cookies y tracking:</li></h4>
                        <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                            Al igual que muchos sitios web, se utilizan cookies y tecnologías similares para recopilar datos adicionales de uso del sitio web y administrar nuestros servicios. Algunos de nuestros servicios pueden no funcionar correctamente si deshabilita las cookies. Cuando su navegador o dispositivo lo permiten utilizamos tanto cookies de sesión como cookies permanentes para conocer mejor cómo interactúa usted con la plataforma, obtener patrones de uso agregado y personalizar y administrar de otras formas nuestros servicios, como proporcionando seguridad a la cuenta, personalizando el contenido que le mostramos, incluyendo los anuncios, y recordando sus preferencias de idioma. Es responsabilidad de cada usuario/a revisar las políticas ofrecidas por sitios de terceros.
                        </p>
                        <h4><li>Notificaciones por correo y Newsletter:</li></h4>
                        <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                            Para efectos de proveer a los usuarios información relevante y de interés para los mismos, BlderDog SpA se reserva el derecho de mantener la información antes señalada bajo su cuidado, elaborando una lista de correos a los cuales se enviará información a los usuarios sobre sus cuentas, cambios en el servicio o nuevas políticas. Los usuarios no podrán optar por eliminar sus correos de dicha lista (salvo en caso de solicitar la cancelación de sus cuentas). Sin embargo, podrán solicitar la cancelación de su suscripción a correos correspondientes a boletines (“newsletters”), cápsulas informativas y otros correos no-administrativos. Al interactuar con los correos electrónicos enviados por BlderDog SpA (por ejemplo, al abrir el correo o al hacer click en los links de su contenido), BlderDog SpA podrá recopilar información sobre dicha interacción.
                        </p>
                    </ol>

                    <h3><li>¿Por qué y para qué usamos los datos?</li></h3>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        En general, usamos los datos personales de cada usuario/a para proveer y mejorar las plataformas. Mediante el uso del sitio o de la aplicación móvil, los usuarios han aceptado la recopilación de determinados datos personales, de acuerdo con la presente política y los términos y condiciones de uso.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        La información recopilada por medio de los mecanismos automáticos antes señalados, y que no contenga identificación personal de los suscriptores y/o usuarios de las plataformas digitales, podrá ser utilizada para informar a entidades públicas, gubernamentales o a terceros sobre patrones de actividad, navegación, audiencia y caracterización general de este sistema.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        Los datos personales de los usuarios recolectados a través de mecanismos manuales u otros medios, serán tratados con objeto de mejorar la calidad de información y contenido de las plataformas digitales, procurando generar mayor interés de la ciudadanía en temas asociados a la política del país, bajo temáticas nacionales, regionales o comunales.
                    </p>

                    <h3><li>Libertad, elección y acceso a la información recopilada.</li></h3>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        Al aceptar la presente política de privacidad, los usuarios autorizan a BlderDog SpA para comunicar a terceros información estadística elaborada a partir de los datos personales de sus usuarios, cuando de dichos datos no sea posible identificar individualmente a los titulares (datos disociados), de conformidad a la Ley y toda normativa aplicable a nivel nacional para la protección de datos personales.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        En todo momento, el usuario podrá ejercer los derechos otorgados por la Ley Nº 19.628 y sus modificaciones posteriores. Esto incluye lo siguiente:
                    </p>
                    <ol type={"a"} style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        <li>
                            <p style={{"textAlign": "justify"}}>
                                Solicitar información respecto de los bancos de datos de que sea responsable el organismo, el fundamento jurídico de su existencia, su finalidad, tipos de datos almacenados y descripción del universo de personas que comprende.
                            </p>
                        </li>
                        <li>
                            <p style={{"textAlign": "justify"}}>
                                Solicitar información sobre los datos relativos a su persona, su procedencia y destinatario, el propósito del almacenamiento y la individualización de las personas u organismos a los cuales sus datos son transmitidos regularmente.
                            </p>
                        </li>
                        <li>
                            <p style={{"textAlign": "justify"}}>
                                Solicitar que se modifiquen sus datos personales cuando ellos no sean correctos o no estén actualizados, si fuere procedente.
                            </p>
                        </li>
                        <li>
                            <p style={{"textAlign": "justify"}}>
                                Solicitar la eliminación o cancelación de los datos entregados cuando así lo desee, en tanto fuere procedente.
                            </p>
                        </li>
                    </ol>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        Las plataformas, tanto a través del sitio como de las apps puede vincularse con servidores de terceros, fuera de nuestro control y dominio, por lo que será responsabilidad de cada usuario/a revisar las políticas de privacidad de dichos sitios. BlderDog SpA no se hace responsable por la recolección, uso, tratamiento y traspaso de los datos obtenidos por servidores de terceros.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        En caso que BlderDog SpA se encontrara en circunstancias de fusionarse, ser adquirida por otra empresa u organización, o cualquier otra circunstancia que ocasionare la transferencia de la información de sus usuarios a una entidad diferente a la actual, o la modificación de sus políticas de privacidad ocasionadas por dichas circunstancias, se informará a los usuarios con antelación, de modo que puedan optar por la aceptación de la nueva política o por la eliminación de sus cuentas antes de la transferencia.
                    </p>

                    <h3><li>Revelación del contenido.</li></h3>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        En las situaciones limitadas en las que BlderDog SpA revele datos personales privados, se hará bajo control de cada usuario/a, porque es importante para administrar los servicios o porque lo exige la ley.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        BlderDog SpA no se hace responsable por la revelación de los contenidos compartidos en la plataforma hecha por terceros. Sin perjuicio de lo anterior, reiteramos el compromiso de estricto cumplimiento de la presente política de privacidad, conforme a las medidas de cuidado y de publicidad acordadas con cada usuario.
                    </p>

                    <h3><li>Seguridad.</li></h3>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        Aparte de las autorizaciones establecidas anteriormente, BlderDog SpA mantendrá la debida confidencialidad de los datos personales y no los trasmitirá a terceros, salvo cuando se deban entregar en razón de un mandato legal o una orden emanada de los Tribunales de Justicia que así lo requiera.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        Sin perjuicio de lo anterior, BlderDog SpA podrá utilizar la información que se describe en esta Política de Privacidad como ayuda para que nuestra publicidad sea más relevante para los usuarios, para medir su efectividad y para ayudarnos a reconocer sus dispositivos para proporcionarle anuncios dentro y fuera de las aplicaciones de BlderDog SpA. Para dichos efectos, BlderDog SpA podrá compartir información a socios publicitarios y afiliados, que a la vez comparten información con BlderDog SpA como ID de cookies de navegador, ID de dispositivos móviles, datos demográficos, y contenido visualizado o acciones realizadas en la página web o la aplicación. Algunos de nuestros socios publicitarios, en concreto nuestros anunciantes, también nos permiten recopilar información similar directamente desde su página web o aplicación integrando nuestra tecnología de publicidad.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        La presente comunicación de los datos para efectos publicitarios, se regirá por la presente política, especialmente en lo referido a la responsabilidad del usuario al aceptar políticas de privacidad, términos y condiciones de terceros ajenos a la aplicación.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        Con el objeto de evitar toda pérdida, mal uso, alteración, acceso no autorizado y/o robo de datos personales o información confidencial facilitados por los usuarios, BlderDog SpA ha adoptado niveles de seguridad y de protección de datos personales según los estándares de la industria, garantizando su resguardo bajo el grado de diligencia legalmente exigido. En consecuencia, los usuarios declaran comprender que, en caso de vulneración mediante acciones ilícitas, por causales de fuerza mayor, o por requerimientos de autoridad, BlderDog SpA no podrá garantizar la total y absoluta inviolabilidad de su base de datos, ni el perfecto funcionamiento de sus mecanismos de protección y resguardo. La seguridad de los datos personales de los usuarios es muy importante para BlderDog SpA, sin embargo, informamos que ningún método de transmisión a través de internet, o de almacenamiento electrónico es totalmente infalible. Sin perjuicio de lo anterior, BlderDog SpA se compromete a implementar la presente política de privacidad y seguridad de forma estricta.
                    </p>

                    <h3><li>Cambios en la presente política de privacidad.</li></h3>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        La presente política de privacidad tendrá vigencia a partir de 1 de octubre de 2023 y de por tiempo indefinido, salvo en lo referido a cualquier cambio en sus cláusulas que se produzca en el futuro. En caso de cualquier modificación, aquella tendrá efecto a partir de su publicación en la presente página.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        Nos reservamos el derecho de actualizar o modificar la política de privacidad en cualquier momento, y será responsabilidad de cada usuario/a revisar periódicamente sus cambios.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        Se entenderá que cualquier actividad y/o uso de la plataforma, sea a través de la página web o la aplicación móvil, constituirá la aceptación tácita de las modificaciones realizadas a la presente política, y su consentimiento para la aplicación de la misma en su totalidad.
                    </p>
                    <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                        En caso de realizar cualquier modificación a la presente política, notificaremos a los usuarios a través de un correo electrónico al correo que haya sido informado por los mismos.
                    </p>
                </ol>

                <h3>Contacto</h3>
                <p style={{"textAlign": "justify", margin: "2rem 8rem"}}>
                    En caso de consultas sobre la presente política, o presentar problemas relacionados con la protección de sus datos por BlderDog SpA, solicitamos escribir un correo electrónico a <b><a href = "mailto: admin@blderdog.com">admin@blderdog.com</a></b>
                </p>

                <h2>Fecha de última actualización: Septiembre 2023.</h2>
            </header>
        </div>
    );
}

export default PrivacyPage;