import React, { useEffect } from 'react';

function RedirectPage() {
    useEffect(() => {
        window.location.href ='https://play.google.com/store/apps/details?id=com.blderdog.coleccionaballs';
    }, []);

    return (
        <div className="App">
            <div className="App">Accediendo a Play Store...</div>
        </div>
    );
}

export default RedirectPage;